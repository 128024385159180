<template>
  <article
    ref="Property"
    :class="[
      'ucsc-propertyCard',
      {
        'ucsc-propertyCard--equal-height': equalHeight,
      },
    ]"
  >
    <div
      :class="['ucsc-propertyCard__image', `ucsc-propertyCard__image--${size}`]"
      :style="style"
    >
      <a
        v-if="property.url"
        class="ucsc-propertyCard__link"
        :href="property.url"
        target="_blank"
        :title="property.title || 'Immagine proprietà'"
      ></a>
      <SvgIcon
        v-if="property.features.virtual_tour && !showVirtualTour"
        name="virtual-tour-new"
        class="ucsc-propertyCard__imageVirtualtour"
      />
      <div
        v-if="hasSlot('top-label')"
        class="flex flex-nowrap items-center absolute z-10 top-4 left-4 h-9.6 pl-2 pr-4 text-sm font-bold bg-white rounded-md"
      >
        <SvgIcon :name="computedIconName" class="w-7 min-w-7 h-7 mr-2" />
        <p class="overflow-hidden whitespace-nowrap">
          <slot name="top-label"></slot>
        </p>
      </div>
      <div v-if="hasSlot('top-right')" class="absolute z-10 top-4 right-4">
        <slot name="top-right"></slot>
      </div>
      <div v-if="property.location.city" class="ucsc-propertyCard__imageGeo">
        <SvgIcon
          :name="`${computedIconName}-white`"
          class="w-7 h-7 mr-2"
          aria-hidden="true"
        />
        <span>{{ property.location.city }}</span>
      </div>
      <div
        v-if="showNotify || showAdd || showHide"
        class="ucsc-propertyCard__actions-wrapper"
      >
        <LazyPropertyActions
          :property="property"
          :show-notify="showNotify"
          :show-add="showAdd"
          :show-hide="showHide"
          icon-size="small"
          @hidden-add="$emit('hidden-add')"
          @favorite-remove="$emit('favorite-remove', $event)"
          @hidden-remove="$emit('hidden-remove', $event)"
        />
      </div>
    </div>
    <div
      class="ucsc-propertyCard__body"
      :class="{ 'ucsc-propertyCard__body--equal-height': equalHeight }"
    >
      <p
        v-if="showTitle"
        v-dompurify-html="property.title"
        class="font-bold text-xl"
      ></p>
      <ul
        :class="[
          'ucsc-propertyCard__features',
          {
            'ucsc-propertyCard__features--light': showTitle,
            'ucsc-propertyCard__features--small':
              property.transaction.price_upon_request,
          },
        ]"
      >
        <li class="ucsc-propertyCard__feature">
          {{ propertyPrice(property) }}
        </li>
        <li
          v-for="(feat, i) in cardFeatures"
          :key="i"
          v-dompurify-html="feat"
          :class="[
            'ucsc-propertyCard__feature',
            {
              'ucsc-propertyCard__feature': i === cardFeatures.length - 1,
            },
          ]"
        ></li>
      </ul>
      <p
        v-if="property.location.locality.neighbourhood && !showTitle"
        class="truncate"
        :title="property.location.locality.neighbourhood"
        v-text="property.location.locality.neighbourhood"
      ></p>
      <slot></slot>
    </div>
  </article>
</template>

<script>
// Libs
import { defineAsyncComponent } from 'vue'

// Static Assets
import { propertyPrice } from '~/assets/js/methods'
import { cardFeatures } from '~/assets/js/computed'
import { iconName } from '~/assets/js/property'

// Components
const LazyPropertyActions = defineAsyncComponent(() =>
  // TODO: test if lazyness is working and necessary
  import('~/components/PropertyActions.vue')
)

export default {
  name: 'PropertyCard',
  components: {
    LazyPropertyActions,
  },
  props: {
    property: {
      type: Object,
      default: () => {
        return null
      },
    },
    showNotify: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: true,
    },
    showHide: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    showVirtualTour: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
    },
    equalHeight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardFeatures,

    computedIconName() {
      const { property } = this

      const isLuxury = property.building.is_luxury
      const category = property.building.category.name

      return iconName(category, isLuxury)
    },

    computedImage() {
      const { property } = this

      if (property.image) {
        return this.$utilities.imgAlias(property.image.src)
      }

      if (property.images?.length) {
        return this.$utilities.imgAlias(property.images[0].src)
      }

      return 'assets/img/unicreditsubitocasa-placeholder.png'
    },

    style() {
      const { computedImage } = this
      const url = this.$img(computedImage, {
        width: 270,
        height: 162,
      })

      return `background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.16) 70%, rgba(0, 0, 0, 0.44)), url(${url})`
    },
  },

  methods: {
    propertyPrice,

    hasSlot(slot) {
      return !!this.$slots[slot]
    },
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/components/property-card';
</style>
